import React, { useEffect } from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'

const Service = () => {
  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })

      var lastId,
        topMenu = window.jQuery('#sidebarsticky'),
        topMenuHeight = topMenu.outerHeight() + 1,
        // All list items
        menuItems = topMenu.find('a'),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function () {
          var item = window.jQuery(window.jQuery(this).attr('href'))
          if (item.length) {
            return item
          }
        })

      // Bind click handler to menu items
      // so we can get a fancy scroll animation
      menuItems.click(function (e) {
        var href = window.jQuery(this).attr('href'),
          offsetTop = href === '#' ? 0 : window.jQuery(href).offset().top - 80
        window.jQuery('html, body').stop().animate(
          {
            scrollTop: offsetTop,
          },
          200
        )
        e.preventDefault()
      })

      // Bind to scroll
      window.jQuery(window).scroll(function () {
        // Get container scroll position
        var fromTop = window.jQuery(this).scrollTop() + topMenuHeight

        // Get id of current scroll item
        var cur = scrollItems.map(function () {
          if (window.jQuery(this).offset().top < fromTop) return this
        })
        // Get the id of the current element
        cur = cur[cur.length - 1]
        var id = cur && cur.length ? cur[0].id : ''

        if (lastId !== id) {
          lastId = id
          // Set/remove active class
          menuItems
            .parent()
            .removeClass('active')
            .end()
            .filter('[href=#' + id + ']')
            .parent()
            .addClass('active')
        }
      })
    }, 700)
  }, [])
  return (
    <>
      <Seo
        title={'Service | immence'}
        description={
          'We offer end-to-end software engineering services, with on-demand teams and resources to carry out full-scale enterprise digital transformation and product development.'
        }
      />
      <Layout>
        <div>
          <section className="content-page bg-service" id="service_page">
            {/* <div class="circle-shadow circle-1"></div>
<div class="circle-shadow circle-2"></div> */}
            <div className="container">
              <div className="row ">
                <div className="col-lg-6">
                  <div className="content_padd">
                    <div className="row">
                      <h2 className="hero-heading">
                        Building <span className="blue-span">innovative</span>
                        <br />
                        <span className="blue-span">Digital</span> solutions
                      </h2>
                    </div>
                    <div className="row">
                      <p className="section-main-para heading-setting mb-0">
                        We offer end-to-end software engineering services, with
                        on-demand teams and resources to carry out full-scale
                        enterprise digital transformation and product
                        development.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 center-item">
                  <div className="service_img">
                    <img
                      src={require('../img/heroImg/services.png').default}
                      alt="services"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="com-section business-leader">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="hero-heading">
                    Ignite your
                    <br />
                    <span className="blue-span"> digital innovation</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="section-main-para">
                      We consult on and implement intelligent software for
                      businesses looking to upgrade their digital ecosystems
                      with more powerful and scalable solutions designed to fit
                      their particular processes. In each of the areas below, we
                      offer access to the latest technologies and our expert
                      assistance in integrating them seamlessly for use by
                      internal users or consumers.
                    </p>
                    <p className="section-main-para">
                      A recognized partner of market-leading technology vendors
                      and innovators, immence is well-equipped to provide
                      expert-level consulting to help you decide on your next
                      move towards digitalization. This includes technology and
                      product roadmapping, legacy software audit, workflow
                      formalization, and implementation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section service-sticky">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <div className="service-lists inner-section" id="csd">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="service-blue-box" />
                        <div className="service-image">
                          <img
                            src={
                              require('../img/custom_development_services.svg')
                                .default
                            }
                            alt="service_ideation_strategy"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="services-content">
                          <div className="service-title">
                            <h2>Custom Software Development</h2>
                          </div>
                          <div className="service-tag">
                            <div className="custom-tags">CRM </div>
                            <div className="custom-tags ms-2"> ERP </div>
                            <div className="custom-tags ms-2"> Web </div>
                            <div className="custom-tags ms-2"> API </div>
                            <div className="custom-tags ms-2"> SAAS </div>
                            <div className="custom-tags ms-2"> Portal </div>
                            <div className="custom-tags ms-2"> Management</div>
                          </div>
                          <div className="service-content card-content">
                            <p>
                              In order to succeed in today's market, businesses
                              must be able to innovate quickly and more
                              efficiently than the competition. Our custom
                              software development services provide the
                              flexibility and functionality required to meet
                              unique industry needs and adapt to an
                              ever-changing landscape.
                            </p>
                            <p>
                              Our track record in Custom Software Development at
                              immence spans a wide range of methodologies and
                              platforms, allowing us to create native and
                              cross-platform solutions for every business need.{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="service-lists inner-section"
                    id="solution_design"
                  >
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="service-blue-box" />
                        <div className="service-image">
                          <img
                            src={
                              require('../img/solutions_design_1.svg').default
                            }
                            alt="service_web_mobile"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="services-content">
                          <div className="service-title">
                            <h2>Solution Design</h2>
                          </div>
                          <div className="service-tag">
                            <div className="custom-tags">Product</div>
                            <div className="custom-tags ms-2">Idea </div>
                            <div className="custom-tags ms-2">Web </div>
                            <div className="custom-tags ms-2">Mobile </div>
                            <div className="custom-tags ms-2">Enterprise </div>
                            <div className="custom-tags ms-2">Software </div>
                          </div>
                          <div className="service-content card-content">
                            <p>
                              {' '}
                              Use a superb brain into your digital product. We
                              implement the most complex solution architecture
                              to establish the foundation of an efficient,
                              performant, and scalable digital solution..
                            </p>
                            <p>
                              From industry-specific business case analysis and
                              tech innovation rationale to digital
                              transformation strategies and risk mitigation
                              plans - We assist you at every stage of the
                              product engineering journey: from product ideation
                              to implementation and support, we deliver an
                              impactful, effective, and scalable digital
                              solution tailored to your business vision.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-lists inner-section" id="webdev">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="service-blue-box" />
                        <div className="service-image">
                          <img
                            src={
                              require('../img/web_development_1.svg').default
                            }
                            alt="service_ideation_strategy"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="services-content">
                          <div className="service-title">
                            <h2>Web Development</h2>
                          </div>
                          <div className="service-tag">
                            <div className="custom-tags">Frontend </div>
                            <div className="custom-tags ms-2">Backend </div>
                            <div className="custom-tags ms-2">Database </div>
                            <div className="custom-tags">Deployment </div>
                            <div className="custom-tags ms-2">Scrapping </div>
                            <div className="custom-tags ms-2">Server </div>
                          </div>
                          <div className="service-content card-content">
                            <p>
                              We develop websites that benefit both your company
                              and users. Our online solutions work seamlessly
                              and consistently across all platforms, whether
                              it's a complicated enterprise platform and data
                              storage, a responsive web app, or a marketing
                              site.
                            </p>
                            <p>
                              Web development services include designing,
                              building, maintaining, and evolving all types of
                              web-based solutions in line with the defined
                              business goals. We have proven experience in
                              building comprehensive yet intuitive websites,
                              complex web portals, and other web software that
                              perfectly matches your business requirements.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-lists inner-section" id="appdev">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="service-blue-box" />
                        <div className="service-image">
                          <img
                            src={require('../img/mobile.svg').default}
                            alt="service_web_mobile"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="services-content">
                          <div className="service-title">
                            <h2>Mobile App Development</h2>
                          </div>
                          <div className="service-tag">
                            <div className="custom-tags">Native </div>
                            <div className="custom-tags ms-2">
                              Cross Platform
                            </div>
                            <div className="custom-tags ms-2">Android </div>
                            <div className="custom-tags ms-2">iOS </div>
                            <div className="custom-tags ms-2">Flutter </div>
                            <div className="custom-tags ms-2">React </div>
                            <div className="custom-tags ms-2">Ionic </div>
                          </div>
                          <div className="service-content card-content">
                            <p>
                              {' '}
                              Bring your business to customers within arm's
                              reach. We build cutting-edge mobile apps with the
                              latest technologies to attract users to your
                              business offering through a magnetic user
                              experience.
                            </p>
                            <p>
                              We provide Mobile App Development services in
                              native as well as cross platform which is
                              compatible for iOS and Android devices. We are
                              committed to providing fast and efficient mobile
                              app development services for clients using the
                              most advanced technologies on the market.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-lists inner-section" id="opensources">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="service-blue-box" />
                        <div className="service-image">
                          <img
                            src={require('../img/game_development.svg').default}
                            alt="service_web_mobile"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="services-content">
                          <div className="service-title">
                            <h2>Game Development</h2>
                          </div>
                          <div className="service-tag">
                            <div className="custom-tags">Unity </div>
                            <div className="custom-tags ms-2">2D </div>
                            <div className="custom-tags ms-2">3D </div>
                            <div className="custom-tags ms-2">Augmented</div>
                            <div className="custom-tags ms-2">Virtual </div>
                            <div className="custom-tags ms-2">AR </div>
                            <div className="custom-tags ms-2">Mixed </div>
                          </div>
                          <div className="service-content card-content">
                            <p>
                              Ignite your game development fantasies with our
                              highly experienced team of game developers. We are
                              a creative game app development firm that operates
                              solely for the purpose of meeting the various
                              needs of our customers.
                            </p>
                            <p>
                              We create stunning 2D and 3D multiplayer games for
                              mobile platforms that feature captivating
                              gameplay. We provide specialized end-to-end
                              outsourcing services for game development. To
                              produce excellent games for mobile and desktop
                              devices, consoles, and wearables, we assembled
                              veterans from the gaming business.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="service-lists inner-section"
                    id="graphicdesign"
                  >
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="service-blue-box" />
                        <div className="service-image">
                          <img
                            src={require('../img/uiux.svg').default}
                            alt="service_artificial_intelligence"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="services-content">
                          <div className="service-title">
                            <h2>UI/UX Design </h2>
                          </div>
                          <div className="service-tag">
                            <div className="custom-tags">Figma </div>
                            <div className="custom-tags ms-2">Adobe </div>
                            <div className="custom-tags ms-2">Zeplin </div>
                            <div className="custom-tags">Prototype </div>
                            <div className="custom-tags ms-2">
                              Interactivity{' '}
                            </div>
                            <div className="custom-tags ms-2"> UI Testing </div>
                          </div>
                          <div className="service-content card-content">
                            <p>
                              We mix product innovation with optimum usability.
                              We produce an engaging and human-centric design
                              that is scalable and enjoyable to use through our
                              iterative design approach and comprehensive
                              prototyping. From the discovery process to the
                              last milestones, our team is present to guarantee
                              that no aspect of your vision is lost in
                              transition.
                            </p>
                            <p>
                              We only need a few conversations to give your
                              project's concept some flesh before we get started
                              on creating a targeted path to your business
                              goals, whether we start from scratch or are
                              already halfway there. This is true whether you
                              have an innovative development idea or are stuck
                              with an existing initiative.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-lists inner-section" id="devops">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="service-blue-box" />
                        <div className="service-image">
                          <img
                            src={require('../img/service_product.svg').default}
                            alt="service_product"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="services-content">
                          <div className="service-title">
                            <h2>DevOps &amp; Cloud</h2>
                          </div>
                          <div className="service-tag">
                            <div className="custom-tags">AWS </div>
                            <div className="custom-tags ms-2">Migration </div>
                            <div className="custom-tags ms-2">Cloud </div>
                            <div className="custom-tags ms-2">DevOps </div>
                            <div className="custom-tags ms-2">Integration </div>
                            <div className="custom-tags ms-2">Deployment </div>
                          </div>
                          <div className="service-content card-content">
                            <p>
                              Lead your cloud development just like the best in
                              business do. Our approach automates software
                              development processes so that workloads may be
                              built, tested, released, and managed more quickly
                              and reliably.
                            </p>
                            <p>
                              With regards to cloud development, integration,
                              and redesign, our consultants can help you migrate
                              to the cloud platform of your choosing. By
                              consolidating development and operations, we help
                              businesses establish transparent and quick
                              software delivery cycles, along with improving the
                              quality of implemented solutions and setting up
                              collaboration-driven, cross-functional teams.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="sidebar-sticky" id="sidebarsticky">
                    <ul>
                      <li className="active">
                        <a href="#csd">Custom Software Development</a>
                      </li>
                      <li>
                        <a href="#solution_design">Solution Design</a>
                      </li>
                      <li className>
                        <a href="#webdev">Web Development</a>
                      </li>
                      <li>
                        <a href="#appdev">Mobile App Development</a>
                      </li>
                      <li>
                        <a href="#opensources">Game Development</a>
                      </li>
                      <li>
                        <a href="#graphicdesign">UI/UX Design </a>
                      </li>
                      <li>
                        <a href="#devops">DevOps &amp; Cloud</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="choose-techno">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-12 aos-init aos-animate service-technology-header"
                  data-aos="fade-up"
                >
                  <h2 className="hero-heading">
                    World-class technology <br /> that inspires digital growth
                  </h2>
                  <p className="section-main-para mt-4">
                    We are well-equipped to provide expert-level consulting{' '}
                    <br />
                    to help you decide on your next move towards technology
                    digitalization.
                  </p>
                </div>
              </div>

              <div className="row mt-5 mb-3">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>Frameworks</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/flutter.svg')
                                  .default
                              }
                              alt="icon_Flutter"
                            />
                          </div>
                          <div className="row">
                            <h4>Flutter</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/react-native.svg')
                                  .default
                              }
                              alt="icon_React Native"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>React Native</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/nodejs.svg')
                                  .default
                              }
                              alt="icon_Node.js"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Node.js</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/dotnet.svg')
                                  .default
                              }
                              alt="icon_.Net"
                            />
                          </div>
                          <div className="row">
                            <h4>.Net</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/ionic.svg')
                                  .default
                              }
                              alt="icon_Ionic"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Ionic</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/odoo.svg')
                                  .default
                              }
                              style={{ height: 32, marginBottom: 10 }}
                              alt="icon_Odoo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Odoo</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/rails.svg')
                                  .default
                              }
                              alt="icon_Rails"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Rails</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/laravel.svg')
                                  .default
                              }
                              alt="icon_Laravel"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Laravel</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/unity.svg')
                                  .default
                              }
                              alt="icon_Unity"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Unity</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/codeigniter.svg')
                                  .default
                              }
                              alt="icon_CodeIgniter"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>CodeIgniter</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/express.svg')
                                  .default
                              }
                              style={{ height: 28, marginBottom: 10 }}
                              alt="icon_Express.js"
                            />
                          </div>
                          <div className="row">
                            <h4>Express.js</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/nextjs.svg')
                                  .default
                              }
                              alt="icon_Next.js"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Next.js</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/saas.svg')
                                  .default
                              }
                              alt="icon_SaaS"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>SaaS</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/framework/xamarin.svg')
                                  .default
                              }
                              alt="icon_Xamarin"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Xamarin</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Mobile */}
              <div className="row aos-init aos-animate mb-3">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>
                      <span>Mobile</span> Development
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/mobile/android.svg')
                                  .default
                              }
                              alt="icon_android"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>Android</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/mobile/ios.svg')
                                  .default
                              }
                              alt="icon_ios"
                            />
                          </div>
                          <div className="row">
                            <h4>iOS</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row aos-init aos-animate mb-3">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>
                      <span>Programming</span> Languages
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/javascript.svg')
                                  .default
                              }
                              alt="icon_javascript"
                            />
                          </div>
                          <div className="row">
                            <h4>JavaScript</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/dart.svg')
                                  .default
                              }
                              alt="icon_dart"
                            />
                          </div>
                          <div className="row">
                            <h4>Dart</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/csharp.svg')
                                  .default
                              }
                              alt="iocn_C#"
                              className="img-fluid"
                            />
                          </div>
                          <div className="row">
                            <h4>C#</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/swift.svg')
                                  .default
                              }
                              alt="icon_Swift"
                            />
                          </div>
                          <div className="row">
                            <h4>Swift</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/kotlin.svg')
                                  .default
                              }
                              alt="iocn_kotlin"
                            />
                          </div>
                          <div className="row">
                            <h4>Kotlin</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/python.svg')
                                  .default
                              }
                              alt="icon_python"
                            />
                          </div>
                          <div className="row">
                            <h4>Python</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/ruby.svg')
                                  .default
                              }
                              alt="icon_ruby"
                            />
                          </div>
                          <div className="row">
                            <h4>Ruby</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/java.svg')
                                  .default
                              }
                              alt="icon_java"
                            />
                          </div>
                          <div className="row">
                            <h4>Java</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/php.svg')
                                  .default
                              }
                              alt="icon_php"
                            />
                          </div>
                          <div className="row">
                            <h4>PHP</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/languages/с++.svg')
                                  .default
                              }
                              alt="iocn_c++"
                            />
                          </div>
                          <div className="row">
                            <h4>C++</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row aos-init aos-animate mb-3">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>Frontend</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/react.svg')
                                  .default
                              }
                              alt="icon_React"
                            />
                          </div>
                          <div className="row">
                            <h4>React</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/angular.svg')
                                  .default
                              }
                              alt="icon_Angular"
                            />
                          </div>
                          <div className="row">
                            <h4>Angular</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/tailwind.svg')
                                  .default
                              }
                              alt="icon_Tailwind"
                            />
                          </div>
                          <div className="row">
                            <h4>Tailwind</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/html.svg')
                                  .default
                              }
                              alt="icon_HTML"
                            />
                          </div>
                          <div className="row">
                            <h4>HTML</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/css.svg')
                                  .default
                              }
                              alt="icon_CSS"
                            />
                          </div>
                          <div className="row">
                            <h4>CSS</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/material.svg')
                                  .default
                              }
                              alt="icon_Material"
                            />
                          </div>
                          <div className="row">
                            <h4>Material</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/bootstrap.svg')
                                  .default
                              }
                              alt="icon_Bootstrap"
                            />
                          </div>
                          <div className="row">
                            <h4>Bootstrap</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/jquery.svg')
                                  .default
                              }
                              alt="iocn_jQuery"
                            />
                          </div>
                          <div className="row">
                            <h4>jQuery</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/sass.svg')
                                  .default
                              }
                              alt="icon_Sass"
                            />
                          </div>
                          <div className="row">
                            <h4>Sass</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/frontend/wordpress.svg')
                                  .default
                              }
                              alt="icon_WordPress"
                            />
                          </div>
                          <div className="row">
                            <h4>WordPress</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row aos-init aos-animate mb-3">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>Design</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/figma.svg')
                                  .default
                              }
                              alt="iocn_Figma"
                            />
                          </div>
                          <div className="row">
                            <h4>Figma</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/xd.svg')
                                  .default
                              }
                              alt="icon_XD"
                            />
                          </div>
                          <div className="row">
                            <h4>XD</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/zeplin.svg')
                                  .default
                              }
                              alt="iocn_Zeplin"
                            />
                          </div>
                          <div className="row">
                            <h4>Zeplin</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/blender.svg')
                                  .default
                              }
                              alt="icon_Blender"
                            />
                          </div>
                          <div className="row">
                            <h4>Blender</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/after-effects.svg')
                                  .default
                              }
                              alt="icon_After-Effects"
                            />
                          </div>
                          <div className="row">
                            <h4>After Effects</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/photoshop.svg')
                                  .default
                              }
                              alt="icon_photoshop"
                            />
                          </div>
                          <div className="row">
                            <h4>Photoshop</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/illustrator.svg')
                                  .default
                              }
                              alt="icon_Illustrator"
                            />
                          </div>
                          <div className="row">
                            <h4>Illustrator</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/coreldraw.svg')
                                  .default
                              }
                              alt="icon_CorelDRAW"
                            />
                          </div>
                          <div className="row">
                            <h4>CorelDRAW</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/design/invision.svg')
                                  .default
                              }
                              alt="icon_InVision"
                            />
                          </div>
                          <div className="row">
                            <h4>InVision</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row aos-init aos-animate mb-3">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>Databases</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/core-data.svg')
                                  .default
                              }
                              alt="icon_Core-Data"
                            />
                          </div>
                          <div className="row">
                            <h4>Core Data</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/mysql.svg')
                                  .default
                              }
                              alt="icon_MySQL"
                            />
                          </div>
                          <div className="row">
                            <h4>MySQL</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/mongodb.svg')
                                  .default
                              }
                              alt="icon_MongoDB"
                            />
                          </div>
                          <div className="row">
                            <h4>MongoDB</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/postgresql.svg')
                                  .default
                              }
                              alt="icon_PostgreSQL"
                            />
                          </div>
                          <div className="row">
                            <h4>PostgreSQL</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/sqlite.svg')
                                  .default
                              }
                              alt="icon_SQLite"
                            />
                          </div>
                          <div className="row">
                            <h4>SQLite</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/oracle.svg')
                                  .default
                              }
                              alt="icon_Oracle"
                            />
                          </div>
                          <div className="row">
                            <h4>Oracle</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/sap.svg')
                                  .default
                              }
                              alt="icon_SAP"
                            />
                          </div>
                          <div className="row">
                            <h4>SAP</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/sql-server.svg')
                                  .default
                              }
                              alt="icon_SQL-Server"
                            />
                          </div>
                          <div className="row">
                            <h4>SQL Server</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/databases/realm.svg')
                                  .default
                              }
                              alt="icon_Realm"
                            />
                          </div>
                          <div className="row">
                            <h4>Realm</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row aos-init aos-animate mb-3">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>Cloud Providers</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/aws.svg')
                                  .default
                              }
                              alt="icon_AWS"
                            />
                          </div>
                          <div className="row">
                            <h4>AWS</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/apache.svg')
                                  .default
                              }
                              alt="icon_Apache"
                            />
                          </div>
                          <div className="row">
                            <h4>Apache</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/digitalocean.svg')
                                  .default
                              }
                              alt="icon_Digital_Ocean"
                            />
                          </div>
                          <div className="row">
                            <h4>Digital Ocean</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/azure.svg')
                                  .default
                              }
                              alt="icon_Azure"
                            />
                          </div>
                          <div className="row">
                            <h4>Azure</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/google-cloud.svg')
                                  .default
                              }
                              alt="icon_Google_Cloud"
                            />
                          </div>
                          <div className="row">
                            <h4>Google Cloud</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/nginx.svg')
                                  .default
                              }
                              alt="icon_Nginx"
                            />
                          </div>
                          <div className="row">
                            <h4>Nginx</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/vultr.svg')
                                  .default
                              }
                              alt="icon_Vultr"
                            />
                          </div>
                          <div className="row">
                            <h4>Vultr</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/contabo.svg')
                                  .default
                              }
                              alt="icon_Contabo"
                            />
                          </div>
                          <div className="row">
                            <h4>Contabo</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/cloud_providers/heroku.svg')
                                  .default
                              }
                              alt="icon_Heroku"
                            />
                          </div>
                          <div className="row">
                            <h4>Heroku</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row aos-init aos-animate mb-5">
                <div className="row">
                  <div
                    className="col-md-12 service-technology-container aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <h2>DevOps</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="service-tehno-list row mt-4">
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/git.svg')
                                  .default
                              }
                              alt="icon_Git"
                            />
                          </div>
                          <div className="row">
                            <h4>Git</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/jira.svg')
                                  .default
                              }
                              alt="iocn_Jira"
                            />
                          </div>
                          <div className="row">
                            <h4>Jira</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/bitbucket.svg')
                                  .default
                              }
                              alt="icon_Bitbucket"
                            />
                          </div>
                          <div className="row">
                            <h4>Bitbucket</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div
                            className="row"
                            style={{ justifyContent: 'center' }}
                          >
                            <img
                              src={
                                require('../img/technology/devops/circleci.svg')
                                  .default
                              }
                              alt="icon_CircleCI"
                              style={{ width: 70 }}
                            />
                          </div>
                          <div className="row">
                            <h4>CircleCI</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/docker.svg')
                                  .default
                              }
                              alt="icon_Docker"
                            />
                          </div>
                          <div className="row">
                            <h4>Docker</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/firebase.svg')
                                  .default
                              }
                              alt="icon_Firebase"
                            />
                          </div>
                          <div className="row">
                            <h4>Firebase</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/github.svg')
                                  .default
                              }
                              alt="icon_GitHub"
                            />
                          </div>
                          <div className="row">
                            <h4>GitHub</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/gitlab.svg')
                                  .default
                              }
                              alt="icon_GitLab"
                            />
                          </div>
                          <div className="row">
                            <h4>GitLab</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/testflight.svg')
                                  .default
                              }
                              alt="icon_TestFlight"
                            />
                          </div>
                          <div className="row">
                            <h4>TestFlight</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/jenkins.svg')
                                  .default
                              }
                              alt="icon_Jenkins"
                            />
                          </div>
                          <div className="row">
                            <h4>Jenkins</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/code.svg')
                                  .default
                              }
                              alt="icon_Code"
                            />
                          </div>
                          <div className="row">
                            <h4>Code</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/bugsnag.svg')
                                  .default
                              }
                              alt="icon_Bugsnag"
                            />
                          </div>
                          <div className="row">
                            <h4>Bugsnag</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/postman.svg')
                                  .default
                              }
                              alt="icon_Postman"
                            />
                          </div>
                          <div className="row">
                            <h4>Postman</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/selenium.svg')
                                  .default
                              }
                              alt="iocn_Selenium"
                            />
                          </div>
                          <div className="row">
                            <h4>Selenium</h4>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="service-tehno-item col-md-2 col-4 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        <figure>
                          <div className="row">
                            <img
                              src={
                                require('../img/technology/devops/appium.svg')
                                  .default
                              }
                              alt="iocn_Appium"
                            />
                          </div>
                          <div className="row">
                            <h4>Appium</h4>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default Service
